import React from 'react';
import { ThemeProvider } from 'styled-components';
import { MobileView, BrowserView, isIE } from 'react-device-detect';
import CookieBar from 'components/CookieBar/CookieBar';
import GlobalStyle from 'theme/GlobalStyle';
import { theme } from 'theme/theme';

import Navigation from 'components/Navigation/Navigation';
import ScrollWrapper from 'components/ScrollWrapper/ScrollWrapper';

const Layout = ({ children, relatedPages = false }) => {
  if (isIE) {
    return <div>Internet Explorer nie jest wspierany.</div>;
  }
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <MobileView>
          <Navigation relatedPages={relatedPages} />
          <main>{children}</main>
        </MobileView>
        <BrowserView>
          <Navigation relatedPages={relatedPages} />
          <ScrollWrapper>
            <main>{children}</main>
          </ScrollWrapper>
        </BrowserView>
        <CookieBar />
      </ThemeProvider>
    </>
  );
};

export default Layout;
