import React from 'react';

import Icon from 'components/atoms/icons/icon';

import { StyledSocialList, StyledSocialItem, StyledSocialLink } from './SocialStyles';

const Social = ({ socialsData, fullWidth }) => (
  <StyledSocialList fullWidth={fullWidth}>
    {socialsData &&
      socialsData.map(({ id, name, url }) => (
        <StyledSocialItem key={id}>
          <StyledSocialLink href={url} target="_blank">
            <Icon name={name} />
          </StyledSocialLink>
        </StyledSocialItem>
      ))}
  </StyledSocialList>
);

export default Social;
