export const colors = {
  white: 'hsl(0, 0%, 100%)',
  gray: 'hsl(0, 0%, 90%)',
  black: 'hsl(0, 0%, 18%)',
  purple: 'hsl(259, 47%, 30%)',
  purpleLightest: 'hsl(258, 53%, 34%)',
  purpleLighter: 'hsl(258, 36%, 48%)',
  purpleLight: 'hsl(259, 48.6%, 86.3%)',
  purpleDark: 'hsl(259, 39%, 34%)',
  purpleDarker: 'hsl(258, 63%, 28%)',
  purpleDarkest: 'hsl(257, 63%, 25%)',
  green: 'hsl(147, 95%, 31%)',
  red: 'hsl(358, 100%, 67%)',
  pink: 'hsl(325, 88%, 68%)',
  yellow: 'hsla(66, 99%, 66%, 1)',
};

export const breakpoints = {
  xs: 320,
  sm: 400,
  smm: 500,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxxl: 1800,
  xxxxl: 2200,
};

export const mq = Object.keys(breakpoints).reduce((acc, breakpoint) => {
  acc[breakpoint] = `@media (min-width: ${breakpoints[breakpoint]}px)`;
  return acc;
}, {});

export const theme = {
  colors,
  mq,
};
