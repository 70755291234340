import React from 'react';

import {
  StyledContactList,
  StyledContactItem,
  StyledContactLink,
} from './ContactStyles';

const Contact = ({ contactData, inline }) => (
  <StyledContactList inline={inline}>
    {contactData &&
      contactData.map(({ id, value, type }) => (
        <StyledContactItem key={id}>
          <StyledContactLink
            href={type === 'email' ? `mailto:${value}` : `tel:${value}`}
          >
            {value}
          </StyledContactLink>
        </StyledContactItem>
      ))}
  </StyledContactList>
);

export default Contact;
