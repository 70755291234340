import styled, {css} from "styled-components";

export const StyledSocialList = styled('ul')`
    margin: 0;
    padding: 0;
    display: flex;
    ${({fullWidth}) => (
        fullWidth && css`
            justify-content: space-between;
            width: 100%;
            ${({ theme }) => theme.mq.md} {
                justify-content: flex-end;
            }
        `
    )}
`

export const StyledSocialItem = styled('li')`
    list-style: none;
    padding: 0 1rem;
`

export const StyledSocialLink = styled('a')`
    color:${({ theme }) => theme.colors.white};
`
