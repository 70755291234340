import React from 'react';

const IconFacebook = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5629 1.00458L14.7857 1C11.6655 1 9.64912 3.12509 9.64912 6.41423V8.91055H6.85671C6.61541 8.91055 6.42001 9.1115 6.42001 9.35937V12.9763C6.42001 13.2241 6.61564 13.4249 6.85671 13.4249H9.64912V22.5514C9.64912 22.7993 9.84452 23 10.0858 23H13.7291C13.9704 23 14.1658 22.799 14.1658 22.5514V13.4249H17.4308C17.6721 13.4249 17.8675 13.2241 17.8675 12.9763L17.8688 9.35937C17.8688 9.24036 17.8227 9.12638 17.741 9.04215C17.6592 8.95793 17.5478 8.91055 17.4319 8.91055H14.1658V6.79439C14.1658 5.77727 14.4018 5.26094 15.6916 5.26094L17.5625 5.26025C17.8036 5.26025 17.999 5.0593 17.999 4.81166V1.45317C17.999 1.20576 17.8038 1.00504 17.5629 1.00458Z"
      fill="white"
    />
  </svg>
);

export default IconFacebook;
