import React from 'react';
import { Desktop, Mobile } from './StyledLogo';

const Logo = () => (
  <>
    <Desktop>
      <svg
        width="102"
        height="50"
        viewBox="0 0 102 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.3166 22.4688V12.4721H2.30005V22.4688H0V0.19043H2.30005V10.1395H14.3166V0.19043H16.6167V22.4688H14.3166Z"
          fill="white"
        />
        <path
          d="M30.5578 22.7068C27.4597 22.7068 24.8311 21.5643 22.6719 19.3745C20.5127 17.1848 19.3861 14.4714 19.3861 11.3772C19.3861 8.23537 20.5127 5.56959 22.6719 3.33223C24.8311 1.14248 27.5067 0 30.5578 0C33.6088 0 36.2844 1.09488 38.4906 3.33223C40.6498 5.52198 41.7764 8.23537 41.7764 11.3772C41.7764 14.4714 40.6967 17.1848 38.4906 19.3745C36.2844 21.5643 33.6088 22.7068 30.5578 22.7068ZM30.5578 2.33256C28.1169 2.33256 26.0046 3.23702 24.2678 4.99835C22.5311 6.75967 21.6392 8.90182 21.6392 11.3772C21.6392 13.8526 22.5311 15.9947 24.2678 17.756C26.0046 19.5174 28.1169 20.4218 30.5578 20.4218C32.9986 20.4218 35.1109 19.5174 36.8477 17.756C38.5375 15.9471 39.4294 13.805 39.4294 11.3296C39.4294 8.85421 38.5375 6.71206 36.8007 4.95074C35.1109 3.23702 32.9986 2.33256 30.5578 2.33256Z"
          fill="white"
        />
        <path
          d="M44.8274 22.4688V0.19043H47.1274V20.1362H57.1725V22.4688H44.8274Z"
          fill="white"
        />
        <path
          d="M62.8523 0.238281H60.5522V22.5166H62.8523V0.238281Z"
          fill="white"
        />
        <path
          d="M78.5771 22.4688L71.0198 13.1385L69.33 15.2807V22.4688H67.0299V0.238033H69.33V11.52L78.2954 0.19043H81.2527L72.4749 11.282L81.5812 22.4688H78.5771Z"
          fill="white"
        />
        <path
          d="M99.6531 22.4688L97.0244 16.0423H87.1201L84.4915 22.4688H82.1445L91.2978 0.19043H92.7998L101.953 22.4688H99.6531ZM96.0387 13.7098L92.0488 3.95109L88.0589 13.7098H96.0387Z"
          fill="white"
        />
        <path
          d="M14.3166 49.127V39.1303H2.30005V49.127H0V26.8486H2.30005V36.7977H14.3166V26.8486H16.6167V49.127H14.3166Z"
          fill="white"
        />
        <path
          d="M30.5578 49.365C27.4597 49.365 24.8311 48.2225 22.6719 46.0327C20.5127 43.843 19.3861 41.1296 19.3861 38.0354C19.3861 34.8936 20.5127 32.2278 22.6719 29.9904C24.8311 27.8007 27.5067 26.6582 30.5578 26.6582C33.6558 26.6582 36.2844 27.7531 38.4906 29.9904C40.6498 32.1802 41.7764 34.8936 41.7764 38.0354C41.7764 41.1296 40.6967 43.843 38.4906 46.0327C36.2844 48.2225 33.6088 49.365 30.5578 49.365ZM30.5578 28.9908C28.1169 28.9908 26.0046 29.8952 24.2678 31.6565C22.5311 33.4179 21.6392 35.56 21.6392 38.0354C21.6392 40.5108 22.5311 42.6529 24.2678 44.4142C26.0046 46.1756 28.1169 47.08 30.5578 47.08C32.9986 47.08 35.1109 46.1756 36.8477 44.4142C38.5375 42.6053 39.4294 40.4632 39.4294 37.9878C39.4294 35.5124 38.5375 33.3703 36.8007 31.6089C35.1109 29.8952 32.9986 28.9908 30.5578 28.9908Z"
          fill="white"
        />
        <path
          d="M44.8274 49.127V26.8486H47.1274V46.7944H57.1725V49.127H44.8274Z"
          fill="white"
        />
        <path
          d="M62.8523 26.8965H60.5522V49.1748H62.8523V26.8965Z"
          fill="white"
        />
        <path
          d="M78.5771 49.127L71.0198 39.7967L69.33 41.9389V49.127H67.0299V26.8962H69.33V38.1782L78.2954 26.8486H81.2527L72.4749 37.9402L81.5812 49.127H78.5771Z"
          fill="white"
        />
        <path
          d="M99.6531 49.127L97.0244 42.7005H87.1201L84.4915 49.127H82.1445L91.2978 26.8486H92.7998L101.953 49.127H99.6531ZM96.0387 40.368L92.0488 30.6093L88.0589 40.368H96.0387Z"
          fill="white"
        />
      </svg>
    </Desktop>
    <Mobile>
      <svg
        width="58"
        height="28"
        viewBox="0 0 58 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.14082 12.7178V7.0593H1.30787V12.7178H0V0.107422H1.30787V5.73898H8.14082V0.107422H9.44869V12.7178H8.14082Z"
          fill="white"
        />
        <path
          d="M17.3759 12.8529C15.6143 12.8529 14.1196 12.2062 12.8918 10.9667C11.664 9.72724 11.0234 8.19136 11.0234 6.43992C11.0234 4.66153 11.664 3.1526 12.8918 1.88617C14.1196 0.646686 15.641 0 17.3759 0C19.1109 0 20.6323 0.619741 21.8868 1.88617C23.1146 3.12565 23.7551 4.66153 23.7551 6.43992C23.7551 8.19136 23.1412 9.72724 21.8868 10.9667C20.6323 12.2062 19.1109 12.8529 17.3759 12.8529ZM17.3759 1.32032C15.988 1.32032 14.7869 1.83228 13.7993 2.82925C12.8117 3.82623 12.3046 5.03876 12.3046 6.43992C12.3046 7.84107 12.8117 9.05361 13.7993 10.0506C14.7869 11.0476 15.988 11.5595 17.3759 11.5595C18.7639 11.5595 19.965 11.0476 20.9526 10.0506C21.9135 9.02666 22.4206 7.81413 22.4206 6.41297C22.4206 5.01182 21.9135 3.79928 20.9259 2.80231C19.965 1.83228 18.7639 1.32032 17.3759 1.32032Z"
          fill="white"
        />
        <path
          d="M25.4902 12.7178V0.107422H26.7981V11.3975H32.51V12.7178H25.4902Z"
          fill="white"
        />
        <path
          d="M35.7395 0.134766H34.4316V12.7451H35.7395V0.134766Z"
          fill="white"
        />
        <path
          d="M44.681 12.7178L40.3837 7.43653L39.4229 8.64907V12.7178H38.115V0.134367H39.4229V6.5204L44.5209 0.107422H46.2024L41.2112 6.38567L46.3893 12.7178H44.681Z"
          fill="white"
        />
        <path
          d="M56.6653 12.7178L55.1706 9.0802H49.5387L48.044 12.7178H46.7095L51.9143 0.107422H52.7684L57.9732 12.7178H56.6653ZM54.6101 7.75988L52.3413 2.2361L50.0726 7.75988H54.6101Z"
          fill="white"
        />
        <path
          d="M8.14082 27.8067V22.1482H1.30787V27.8067H0V15.1963H1.30787V20.8279H8.14082V15.1963H9.44869V27.8067H8.14082Z"
          fill="white"
        />
        <path
          d="M17.3759 27.9418C15.6143 27.9418 14.1196 27.2951 12.8918 26.0556C11.664 24.8161 11.0234 23.2802 11.0234 21.5288C11.0234 19.7504 11.664 18.2415 12.8918 16.975C14.1196 15.7356 15.641 15.0889 17.3759 15.0889C19.1376 15.0889 20.6323 15.7086 21.8868 16.975C23.1146 18.2145 23.7551 19.7504 23.7551 21.5288C23.7551 23.2802 23.1412 24.8161 21.8868 26.0556C20.6323 27.2951 19.1109 27.9418 17.3759 27.9418ZM17.3759 16.4092C15.988 16.4092 14.7869 16.9211 13.7993 17.9181C12.8117 18.9151 12.3046 20.1276 12.3046 21.5288C12.3046 22.9299 12.8117 24.1425 13.7993 25.1394C14.7869 26.1364 15.988 26.6484 17.3759 26.6484C18.7639 26.6484 19.965 26.1364 20.9526 25.1394C21.9135 24.1155 22.4206 22.903 22.4206 21.5018C22.4206 20.1007 21.9135 18.8881 20.9259 17.8912C19.965 16.9211 18.7639 16.4092 17.3759 16.4092Z"
          fill="white"
        />
        <path
          d="M25.4902 27.8067V15.1963H26.7981V26.4864H32.51V27.8067H25.4902Z"
          fill="white"
        />
        <path
          d="M35.7395 15.2236H34.4316V27.834H35.7395V15.2236Z"
          fill="white"
        />
        <path
          d="M44.681 27.8067L40.3837 22.5254L39.4229 23.7379V27.8067H38.115V15.2232H39.4229V21.6093L44.5209 15.1963H46.2024L41.2112 21.4745L46.3893 27.8067H44.681Z"
          fill="white"
        />
        <path
          d="M56.6653 27.8067L55.1706 24.1691H49.5387L48.044 27.8067H46.7095L51.9143 15.1963H52.7684L57.9732 27.8067H56.6653ZM54.6101 22.8487L52.3413 17.325L50.0726 22.8487H54.6101Z"
          fill="white"
        />
      </svg>
    </Mobile>
  </>
);

export default Logo;
