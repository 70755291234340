import styled from 'styled-components';

export const StyledScrollWrapper = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const StyledScrollContainer = styled('div')`
  padding: 0;
`;
