import React from 'react';
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl';

import {
  StyledSelectLanguageList,
  StyledSelectLanguageItem,
  StyledLinkToRelatedPage,
} from './SelectLanguageStyles';

const SelectLanguage = ({ relatedPages = false }) => {
  const languageName = {
    pl: 'PL',
    en: 'EN',
  };

  return (
    <StyledSelectLanguageList>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => {
            if (relatedPages) {
              const relatedPage = relatedPages.find(
                item => item.locale === language
              );
              return (
                <StyledLinkToRelatedPage
                  key={language}
                  selected={currentLocale === language}
                  to={`/${relatedPage.locale}/${relatedPage.slug}`}
                >
                  {languageName[language]}
                </StyledLinkToRelatedPage>
              );
            }
            return (
              <StyledSelectLanguageItem
                key={language}
                onClick={() => changeLocale(language)}
                selected={currentLocale === language}
              >
                {languageName[language]}
              </StyledSelectLanguageItem>
            );
          })
        }
      </IntlContextConsumer>
    </StyledSelectLanguageList>
  );
};

export default SelectLanguage;
