import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { CSSPlugin } from 'gsap/CSSPlugin';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(CSSPlugin);

export const showMenu = element => {
  gsap.to(element, {
    duration: 1,
    css: { display: 'none' },
  });
};

export const hideMenu = element => {
  gsap.to(element, {
    duration: 0,
    css: { display: 'block' },
  });
};

export const staggerReveal = (element1, element2) => {
  gsap.to([element1, element2], {
    duration: 0,
    opacity: 1,
    height: '100%',
  });
  gsap.from([element1, element2], {
    duration: 0.8,
    height: 0,
    transformOrigin: 'right top',
    skewY: 3,
    ease: 'power3.inOut',
    stagger: {
      amount: 0.1,
    },
  });
};

export const staggerRevealClose = (element1, element2) => {
  gsap.to([element1, element2], {
    duration: 0.8,
    height: 0,
    ease: 'power3.inOut',
    stagger: {
      amount: 0.07,
    },
  });
};

export const staggerText = elements => {
  gsap.from(elements.current, {
    duration: 0.8,
    y: 100,
    delay: 0.1,
    ease: 'power3.inOut',
    stagger: {
      amount: 0.3,
    },
  });
};

export const fadeInUp = (...elements) => {
  gsap.from(...elements, {
    y: 120,
    duration: 1,
    delay: 0.8,
    opacity: 0,
    ease: 'power3.inOut',
    stagger: {
      amount: 0.2,
    },
  });
};

export const navigationAnimation = elements => {
  gsap.from(elements.current, {
    y: -200,
    x: 20,
    duration: 1,
    delay: 0.8,
    opacity: 0,
    ease: 'power3.inOut',
    stagger: {
      amount: 0.3,
    },
  });
};

export const heroAnimation = (...elements) => {
  gsap.from(...elements, {
    y: 30,
    duration: 1,
    delay: 1.5,
    opacity: 0,
    ease: 'power3.inOut',
    stagger: {
      amount: 0.2,
    },
  });
};

export const aboutUsAnimation = (triggerElement, element, content) => {
  gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerElement,
        scrub: 2,
        start: 'top 60%',
        end: 'top top',
      },
    })
    .from(element, {
      autoAlpha: 0,
      opacity: 0,
      y: 1000,
      duration: 1.2,
      ease: 'power3.out',
    })
    .from(
      element.children,
      {
        duration: 2,
        scale: 1.6,
        ease: 'power3.out',
      },
      0.2
    )
    .from(
      [content.children[0], content.children[1], content.children[2]],
      {
        autoAlpha: 0,
        opacity: 0,
        duration: 1,
        y: 60,
        ease: 'power3.out',
        stagger: {
          amount: 0.2,
        },
      },
      0.8
    );
};

export const makeupAnimation = (
  triggerElement,
  title,
  titleAlt,
  content
  // button
) => {
  gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerElement,
        scrub: 2,
        start: 'top 60%',
        end: 'top 20%',
      },
    })
    .from(titleAlt, {
      autoAlpha: 0,
      opacity: 0,
      duration: 1,
      y: 60,
      ease: 'power3.out',
    })
    .from(
      [
        title,
        content,
        // button
      ],
      {
        autoAlpha: 0,
        opacity: 0,
        duration: 1,
        y: 60,
        ease: 'power3.inOut',
        stagger: {
          amount: 0.4,
        },
      },
      0.4
    );
};

export const sliderAnimation = (triggerElement, heading, slider) => {
  gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerElement,
        scrub: 2,
        start: 'top bottom',
        end: 'top top',
      },
    })
    .from([heading, slider], {
      autoAlpha: 0,
      y: 120,
      duration: 1,
      ease: 'power3.inOut',
      stagger: {
        amount: 0.3,
      },
    });
};

export const ritualAnimation = (item1, item2) => {
  gsap
    .timeline({
      scrollTrigger: {
        trigger: item1,
        scrub: 2,
        start: 'top 80%',
        end: 'top 50%',
      },
    })
    .from(
      [item1.children[0], item1.children[1]],
      {
        autoAlpha: 0,
        opacity: 0,
        duration: 1,
        y: 140,
        ease: 'power3.out',
        stagger: {
          amount: 0.4,
        },
      },
      0.4
    );

  gsap
    .timeline({
      scrollTrigger: {
        trigger: item2,
        scrub: 2,
        start: 'top 80%',
        end: 'top 50%',
      },
    })
    .from(
      [item2.children[1], item2.children[0]],
      {
        autoAlpha: 0,
        opacity: 0,
        duration: 1,
        y: 140,
        ease: 'power3.out',
        stagger: {
          amount: 0.4,
        },
      },
      0.4
    );
};

export const moisturizeAnimation = (triggerElement, content, image) => {
  gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerElement,
        scrub: 2,
        start: 'top 60%',
        end: 'top top',
      },
    })
    .from(image, {
      autoAlpha: 0,
      opacity: 0,
      x: -150,
      duration: 1,
      ease: 'power3.out',
    })
    .from(
      [content.children[0], content.children[1]],
      {
        autoAlpha: 0,
        opacity: 0,
        duration: 1,
        y: 140,
        ease: 'power3.out',
        stagger: {
          amount: 0.4,
        },
      },
      0.4
    );
};

export const careAnimation = (triggerElement, title, text, items) => {
  gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerElement,
        scrub: 2,
        start: 'top 70%',
        end: 'top 15%',
      },
    })
    .from([title, text], {
      autoAlpha: 0,
      y: 120,
      duration: 2,
      ease: 'power3.out',
      stagger: {
        amount: 0.3,
      },
    })
    .from([...items.children], {
      x: 600,
      duration: 4,
      autoAlpha: 0,
      ease: 'power3.inOut',
      stagger: {
        amount: 1,
      },
    });
};

export const suggestionsAnimation = (triggerElement, items) => {
  gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerElement,
        start: 'top 90%',
        end: 'top 40%',
        scrub: 2,
      },
    })
    .from(
      [...items],
      {
        autoAlpha: 0,
        opacity: 0,
        duration: 1,
        y: 100,
        ease: 'power3.out',
        stagger: {
          amount: 0.2,
        },
      },
      0.8
    );
};

export const cleanAnimation = (triggerElement, elements, title, subtitle) => {
  gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerElement,
        start: 'top bottom',
        end: 'top top',
        scrub: 2,
      },
    })
    .from([title, subtitle], {
      autoAlpha: 0,
      y: 120,
      duration: 1,
      ease: 'power3.inOut',
      stagger: {
        amount: 0.3,
      },
    })
    .from(elements.current, {
      autoAlpha: 0,
      y: 200,
      duration: 1,
      ease: 'power3.inOut',
      stagger: {
        amount: 0.8,
      },
    });
};

export const aloesAnimation = (triggerElement, ...elements) => {
  gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerElement,
        start: 'top 60%',
        end: 'top top',
        scrub: 2,
      },
    })
    .from(...elements, {
      x: -700,
      duration: 2,
      ease: 'power3.inOut',
      stagger: {
        amount: 0.5,
      },
    });
};

export const benefitsAnimation = (triggerElement, title, elements) => {
  gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerElement,
        start: 'top 60%',
        end: 'top top',
        scrub: 2,
      },
    })
    .from(title, {
      autoAlpha: 0,
      opacity: 0,
      y: 220,
      duration: 1.2,
      ease: 'power3.out',
    })
    .from(
      [...elements.children],
      {
        autoAlpha: 0,
        opacity: 0,
        duration: 1,
        y: 60,
        ease: 'power3.out',
        stagger: {
          amount: 0.8,
        },
      },
      0.8
    );
};

export const dotAnimation = (triggerElement, product) => {
  gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerElement,
        start: 'top 80%',
        end: 'top 60%',
        scrub: 2,
      },
    })
    .from(
      product,
      {
        autoAlpha: 0,
        opacity: 0,
        duration: 1,
        y: 120,
        ease: 'power3.out',
        stagger: {
          amount: 0.8,
        },
      },
      0.8
    );
};

export const masksAnimation = (
  triggerElement,
  title,
  triggerSecondElement,
  subtitle,
  paragraph,
  slider,
  content
) => {
  gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerElement,
        start: 'top 60%',
        end: 'top top',
        scrub: 2,
      },
    })
    .from(
      [slider, title],
      {
        autoAlpha: 0,
        opacity: 0,
        duration: 1,
        y: 60,
        ease: 'power3.out',
        stagger: {
          amount: 0.3,
        },
      },
      0.2
    );

  gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerSecondElement,
        start: 'top 60%',
        end: 'top top',
        scrub: 2,
      },
    })
    .from(
      [content.nextElementSibling, subtitle, paragraph],
      {
        autoAlpha: 0,
        opacity: 0,
        duration: 1,
        y: 60,
        ease: 'power3.out',
        stagger: {
          amount: 0.3,
        },
      },
      0.2
    );
};

export const gelAnimation = (
  triggerElement,
  title,
  firstCol,
  middleCol,
  lastCol
) => {
  gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerElement,
        start: 'top 80%',
        end: 'top center',
        scrub: 2,
      },
    })
    .from(
      title,
      {
        autoAlpha: 0,
        opacity: 0,
        duration: 1,
        y: 60,
        ease: 'power3.out',
      },
      0.8
    );

  gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerElement,
        start: 'top 60%',
        end: 'top top',
        scrub: 2,
      },
    })
    .from(
      [...middleCol.children],
      {
        autoAlpha: 0,
        opacity: 0,
        duration: 1,
        y: 60,
        ease: 'power3.out',
        stagger: {
          amount: 0.2,
        },
      },
      0.8
    );

  gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerElement,
        start: 'top 50%',
        end: 'bottom 80%',
        scrub: 2,
      },
    })
    .from(
      [...firstCol.children],
      {
        autoAlpha: 0,
        opacity: 0,
        duration: 1,
        y: 120,
        ease: 'power3.out',
        stagger: {
          amount: 0.2,
        },
      },
      0.5
    );

  gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerElement,
        start: 'top 40%',
        end: 'bottom bottom',
        scrub: 2,
      },
    })
    .from(
      [...lastCol.children],
      {
        autoAlpha: 0,
        opacity: 0,
        duration: 1,
        y: 120,
        ease: 'power3.out',
        stagger: {
          amount: 0.2,
        },
      },
      0.8
    );
};

export const bbAnimation = (
  triggerElement,
  title,
  subtitle,
  function1,
  function2,
  function3,
  sectionBg,
  productsBg
) => {
  gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerElement,
        start: 'top 60%',
        end: 'top top',
        scrub: 2,
      },
    })
    .from([title, subtitle], {
      autoAlpha: 0,
      y: 100,
      duration: 1,
      ease: 'power3.inOut',
      stagger: {
        amount: 0.2,
      },
    })
    .from([function1, function2, function3], {
      autoAlpha: 0,
      y: 120,
      duration: 1,
      ease: 'power3.inOut',
      stagger: {
        amount: 0.5,
      },
    });

  gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerElement,
        start: 'top 40%',
        end: 'center center',
        scrub: 2,
      },
    })
    .to([...sectionBg.children], {
      y: '30%',
      duration: 1,
    });

  gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerElement,
        start: 'top 50%',
        end: 'bottom top',
        scrub: 2,
      },
    })
    .to(productsBg.children, {
      y: '-30%',
      duration: 1,
    });
};

export const polandAnimation = (
  triggerElement,
  title,
  map,
  content,
  companies
) => {
  gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerElement,
        scrub: 2,
        start: 'top bottom',
        end: 'top top',
      },
    })
    .from(
      title,
      {
        autoAlpha: 0,
        opacity: 0,
        duration: 1,
        y: 60,
        ease: 'power3.out',
      },
      0.2
    )
    .from(map, {
      autoAlpha: 0,
      opacity: 0,
      y: 200,
      duration: 1.5,
      ease: 'power3.out',
    })
    .from(
      [
        content.children[0],
        content.children[1],
        content.children[2],
        content.children[3],
      ],
      {
        autoAlpha: 0,
        opacity: 0,
        duration: 1,
        y: 60,
        ease: 'power3.out',
        stagger: {
          amount: 0.3,
        },
      },
      2
    );

  gsap
    .timeline({
      scrollTrigger: {
        trigger: companies,
        scrub: 2,
        start: 'top bottom',
        end: 'top 50%',
      },
    })
    .from(
      [companies.children[0], companies.children[1]],
      {
        autoAlpha: 0,
        opacity: 0,
        duration: 1,
        y: 60,
        ease: 'power3.out',
        stagger: {
          amount: 0.3,
        },
      },
      1
    );
};

export const formAnimation = (triggerElement, form) => {
  gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerElement,
        scrub: 2,
        start: 'top bottom',
        end: 'top top',
      },
    })
    .from(
      form,
      {
        autoAlpha: 0,
        opacity: 0,
        duration: 1,
        y: 60,
        ease: 'power3.out',
        stagger: {
          amount: 0.3,
        },
      },
      1
    );
};

export const simpleFadeIn = element => {
  gsap.from(element, {
    y: 50,
    duration: 0.8,
    delay: 0.3,
    opacity: 0,
    ease: 'ease-in',
    stagger: {
      amount: 0.2,
    },
  });
};
