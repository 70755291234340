import React from 'react';
import { IntlContextConsumer } from 'gatsby-plugin-intl';

const withLocale = WrappedComponent => props => (
  <IntlContextConsumer>
    {({ language: currentLocale }) => (
      <WrappedComponent currentLocale={currentLocale} {...props} />
    )}
  </IntlContextConsumer>
);

export default withLocale;
