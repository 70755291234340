import React from 'react';

const IconLogo = () => (
  <svg 
    width="126" 
    height="61" 
    viewBox="0 0 126 61" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M17.6852 27.5559V15.2958H2.84123V27.5559H0V0.233398H2.84123V12.4351H17.6852V0.233398H20.5265V27.5559H17.6852Z" 
      fill="white" 
    />
    <path 
      d="M37.7478 27.8479C33.9208 27.8479 30.6737 26.4468 28.0064 23.7612C25.3391 21.0757 23.9475 17.7479 23.9475 13.9532C23.9475 10.1 25.3391 6.83062 28.0064 4.0867C30.6737 1.40115 33.9788 0 37.7478 0C41.5168 0 44.8219 1.34277 47.5472 4.0867C50.2144 6.77224 51.6061 10.1 51.6061 13.9532C51.6061 17.7479 50.2724 21.0757 47.5472 23.7612C44.8219 26.4468 41.5168 27.8479 37.7478 27.8479ZM37.7478 2.86069C34.7326 2.86069 32.1233 3.96994 29.9779 6.13005C27.8325 8.29016 26.7308 10.9173 26.7308 13.9532C26.7308 16.989 27.8325 19.6162 29.9779 21.7763C32.1233 23.9364 34.7326 25.0456 37.7478 25.0456C40.763 25.0456 43.3723 23.9364 45.5177 21.7763C47.6051 19.5578 48.7068 16.9306 48.7068 13.8948C48.7068 10.8589 47.6051 8.23178 45.4597 6.07167C43.3723 3.96994 40.763 2.86069 37.7478 2.86069Z" 
      fill="white"
    />
    <path 
      d="M55.375 27.5559V0.233398H58.2162V24.6952H70.6249V27.5559H55.375Z" 
      fill="white"
    />
    <path 
      d="M77.641 0.291992H74.7998V27.6145H77.641V0.291992Z" 
      fill="white"
    />
    <path 
      d="M97.0657 27.5559L87.7302 16.1131L85.6427 18.7403V27.5559H82.8015V0.29178H85.6427V14.1282L96.7178 0.233398H100.371L89.5277 13.8363L100.777 27.5559H97.0657Z" 
      fill="white"
    />
    <path 
      d="M123.101 27.5559L119.853 19.6744H107.619L104.372 27.5559H101.472L112.779 0.233398H114.635L125.942 27.5559H123.101ZM118.636 16.8137L113.707 4.84553L108.778 16.8137H118.636Z" 
      fill="white"
    />
    <path 
      d="M17.6852 60.2498V47.9897H2.84123V60.2498H0V32.9272H2.84123V45.129H17.6852V32.9272H20.5265V60.2498H17.6852Z" 
      fill="white"
    />
    <path 
      d="M37.7478 60.5418C33.9208 60.5418 30.6737 59.1406 28.0064 56.4551C25.3391 53.7695 23.9475 50.4418 23.9475 46.647C23.9475 42.7938 25.3391 39.5245 28.0064 36.7805C30.6737 34.095 33.9788 32.6938 37.7478 32.6938C41.5748 32.6938 44.8219 34.0366 47.5472 36.7805C50.2144 39.4661 51.6061 42.7938 51.6061 46.647C51.6061 50.4418 50.2724 53.7695 47.5472 56.4551C44.8219 59.1406 41.5168 60.5418 37.7478 60.5418ZM37.7478 35.5545C34.7326 35.5545 32.1233 36.6638 29.9779 38.8239C27.8325 40.984 26.7308 43.6112 26.7308 46.647C26.7308 49.6828 27.8325 52.31 29.9779 54.4701C32.1233 56.6302 34.7326 57.7395 37.7478 57.7395C40.763 57.7395 43.3723 56.6302 45.5177 54.4701C47.6051 52.2516 48.7068 49.6245 48.7068 46.5886C48.7068 43.5528 47.6051 40.9256 45.4597 38.7655C43.3723 36.6638 40.763 35.5545 37.7478 35.5545Z" 
      fill="white"
    />
    <path 
      d="M55.375 60.2498V32.9272H58.2162V57.3891H70.6249V60.2498H55.375Z" 
      fill="white"
    />
    <path 
      d="M77.641 32.9858H74.7998V60.3083H77.641V32.9858Z" 
      fill="white"
    />
    <path 
      d="M97.0657 60.2498L87.7302 48.807L85.6427 51.4342V60.2498H82.8015V32.9856H85.6427V46.822L96.7178 32.9272H100.371L89.5277 46.5301L100.777 60.2498H97.0657Z" 
      fill="white"
    />
    <path 
      d="M123.101 60.2498L119.853 52.3683H107.619L104.372 60.2498H101.472L112.779 32.9272H114.635L125.942 60.2498H123.101ZM118.636 49.5076L113.707 37.5394L108.778 49.5076H118.636Z" 
      fill="white"
    />
  </svg>
);

export default IconLogo;
