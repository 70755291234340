import React, { useEffect } from 'react';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { useIntl } from 'gatsby-plugin-intl';
import useStickyState from 'hooks/useStickyState';
import withLocale from 'hoc/withLocale';

import { Helmet } from 'react-helmet';
import {
  StyledBar,
  StyledTextWrapper,
  StyledLink,
  StyledText,
  StyledAccept,
} from './CookieBarStyles';

const CookieBar = ({ currentLocale }) => {
  const intl = useIntl();

  const location = useLocation();
  useEffect(() => {
    initializeAndTrack(location.hostname);
  }, []);

  const [bannerHidden, setBannerHidden] = useStickyState(
    false,
    'consentCookieHidden'
  );

  const handleAcceptCookies = () => {
    document.cookie = 'gatsby-gdpr-google-analytics=true';
    setBannerHidden(true);
  };

  const googleTagId = process.env.GATSBY_GOOGLE_GTAG_ID;

  return (
    <>
      {!bannerHidden ? (
        <StyledBar>
          <StyledTextWrapper>
            <StyledText>
              {intl.formatMessage({ id: 'private_policy.text' })}{' '}
              <StyledLink
                to={`/${currentLocale}/${intl.formatMessage({
                  id: 'private_policy.link.url',
                })}`}
              >
                {intl.formatMessage({ id: 'private_policy.link.label' })}
              </StyledLink>
              .
            </StyledText>
            <StyledAccept onClick={handleAcceptCookies}>OK</StyledAccept>
          </StyledTextWrapper>
        </StyledBar>
      ) : (
        <Helmet>
          {/* Global site tag (gtag.js) - Google Analytics  */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${googleTagId}`}
          />
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', ${googleTagId});
            `}
          </script>
        </Helmet>
      )}
    </>
  );
};

export default withLocale(CookieBar);
