import { useEffect, useState } from 'react';

const useStickyState = (defaultValue, key) => {
  const getValue = (valueKey, value) =>
    typeof window !== 'undefined' && window.localStorage.getItem(valueKey)
      ? JSON.parse(window.localStorage.getItem(valueKey))
      : value;
  const setValue = (valueKey, value) =>
    window.localStorage.setItem(valueKey, JSON.stringify(value));

  const [value, setter] = useState(() => getValue(key, defaultValue));

  useEffect(() => {
    setValue(key, value);
  }, [key, value]);

  return [value, setter];
};

export default useStickyState;
