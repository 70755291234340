import styled from "styled-components";

export const Desktop = styled('div')`
    display: none;

    ${({ theme }) => theme.mq.xl} {
        display: block;
    }
`

export const Mobile = styled('div')`
    display: block;

    ${({ theme }) => theme.mq.xl} {
        display: none;
    }
`
